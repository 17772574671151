
@charset "UTF-8";
@import "../_mixins.scss";

@mixin themed-button($name, $primary, $secondary) {
  .#{$name} {
    border-radius: 25px;
  	cursor: pointer;
  	display: inline-block;
  	font-family: $oswald;
  	font-weight: 700;
  	padding: 11px 28px;
  	text-transform: uppercase;
  	transition: background-color $transition-duration, border-color $transition-duration, color $transition-duration, transform $transition-duration;
  	&, &:visited {
  		color: $primary !important;
      background-color: $secondary;
      border: 3px solid $secondary;
      text-decoration: none;
  	}
    &:hover, &:active {
      color: $secondary !important;
      background-color: $primary;
      border: 3px solid $secondary;
    }
  }
}

@mixin themed-button-outlined($name, $primary, $secondary) {
  .#{$name} {
    border-radius: 25px;
  	cursor: pointer;
  	display: inline-block;
  	font-family: $oswald;
  	font-weight: 700;
  	padding: 11px 28px;
  	text-transform: uppercase;
  	transition: background-color $transition-duration, border-color $transition-duration, color $transition-duration, transform $transition-duration;
  	&, &:visited {
  		color: $secondary !important;
      background-color: $primary;
      border: 3px solid $primary;
      text-decoration: none;
  	}
    &:hover, &:active {
      color: $primary !important;
      background-color: $secondary;
      border: 3px solid $primary;
    }
  }
}

// Buttons for White Backgrounds
// Usage:
//  <a class="btn btn-zip"></a>
//  <button class="btn btn-blue"></button>
@include themed-button(btn-zip, white, $orange);
@include themed-button(btn-orange, white, $orange);
@include themed-button(btn-green, white, $green);
@include themed-button(btn-blue, white, $blue);

// Buttons for Coloured Backgrounds
// Usage:
//  <a class="btn btn-outline-orange"></a>
//  <button class="btn btn-outline-blue"></button>
@include themed-button-outlined(btn-outline-orange, white, $orange);
@include themed-button-outlined(btn-outline-green, white, $green);
@include themed-button-outlined(btn-outline-blue, white, $blue);

// Round button with Social Icon
// Usage:
//   <a class="btn btn-social-blue twitter">White Twitter icon on blue background</a>
//   <a class="btn btn-social-orange yelp">White Yelp icon on orange background</a>
//   <a class="btn btn-social-green instagram">White Instagram icon on green background</a>
//   <a class="btn btn-social-white youtube-orange">Orange Youtube icon on white background</a>
.btn-social {
  background-size: 60% 60%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50px;
  height: 38px;
  overflow: hidden;
  width: 38px;
  margin-right: 5px;
  transition: transform $transition-duration;
  font-size: 1px !important; // hides link text
  &:hover {
    transform: scale(1.2);
  }
  &-white {
    @extend .btn-social;
    background-color: white;
  }
  &-blue {
    @extend .btn-social;
    background-color: $blue;
  }
  &-orange {
    @extend .btn-social;
    background-color: $orange;
  }
  &-green {
    @extend .btn-social;
    background-color: $green;
  }
  &.instagram {
    background-image: url("../images/instagram-white.svg");
  }
  &.instagram-orange {
    background-image: url("../images/instagram-orange.svg");
  }
  &.facebook {
    background-image: url("../images/facebook-white.svg");
  }
  &.facebook-orange {
    background-image: url("../images/facebook-orange.svg");
  }
  &.yelp {
    background-image: url("../images/yelp-white.svg");
    background-position: 55%;
  }
  &.yelp-orange {
    background-image: url("../images/yelp-orange.svg");
    background-position: 55%;
  }
  &.trip-advisor {
    background-image: url("../images/trip-advisor-white.svg");
    background-size: 70%;
  }
  &.trip-advisor-orange {
    background-image: url("../images/trip-advisor-orange.svg");
    background-size: 70%;
  }
  &.twitter {
    background-image: url("../images/twitter-white.svg");
    background-position: 55% 50%;
  }
  &.twitter-orange {
    background-image: url("../images/twitter-orange.svg");
    background-position: 55% 50%;
  }
  &.youtube {
    background-image: url("../images/youtube-white.svg");
  }
  &.youtube-orange {
    background-image: url("../images/youtube-orange.svg");
  }
  &.tiktok {
    background-image: url("../images/tiktok-white.svg");
  }
  &.tiktok-orange {
    background-image: url("../images/tiktok-orange.svg");
  }
}
