@charset "UTF-8";
@import '../_mixins.scss';


.zip-insta {

  padding: 80px 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding: 140px 0 110px 0;
  }

  @include media-breakpoint-up(md) {
    padding-left: 30px;
    padding-right: 30px;
  }


  header {
    h1 {
      color: $blue;
      font-weight: bold;
      font-size: 36px;

      @include media-breakpoint-up(sm) {
        font-size: 50px;
      }

      span {
        font-weight: normal;
      }

    }

    p {
      margin: 1.5em 0 2em 0;
      font-size: 20px;
    }
  }

  .row {
    margin: 0;

    >div {
      padding: 2px;
    }
  }

  .gram {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 100%;

    span {
      background-position: 50% 50%;
      background-size: cover;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform $transition-duration;
      width: 100%;
    }

    @include media-hover {
      &:hover {
        span {
          transform: scale(1.2);
        }

        div {
          opacity: 1;
          padding-top: 10%;
        }
      }
    }

    >a {
      background-size: cover;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      text-decoration: none;
      top: 0;
      width: 100%;
      z-index: 2;

      div {
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        cursor: pointer;
        display: block;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        padding: 20px;
        //        text-align: center;
        transition: opacity $transition-duration, padding-top 0.2s;
        width: 100%;
        z-index: 1;

        pre {
          color: #fff;
          //          font-size: 0.8rem;
          font-size: 1em;
          text-align: left;
          white-space: pre-wrap;
          font-family: $dax;
          overflow: hidden;
          background-color: initial;
          padding: 0px;
          font-size: 16px;
        }

      }
    }
  }

  footer {
    padding-top: 30px;
  }
}