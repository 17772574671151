@charset "UTF-8";
@import '../_mixins.scss';
/**
 * Tour-Grid Styles.  Appears on Tours overview page and home page to showcase
 * available tours in a tiled gid.
 */

.tour-card {
  position: relative; // this is a container for absolute elements
  margin-top: 30px; // match the bootstrap grid gutter
  overflow: hidden; // necessary for reveal-on-hover effect

  .tour-image {
    // source image size depends on the grid
    // eg. col-sm-12 shows one card up to 768px,
    // so source image should be at least 769px
    width: 100%;
    height: auto;
  }

  // the tour-body class represents the orange box overlaid on the image
  .tour-body {
    background: url("data:image/svg+xml;charset=UTF-8;base64,PHN2ZyBoZWlnaHQ9IjM3NCIgdmlld0JveD0iMCAwIDUyOSAzNzQiIHdpZHRoPSI1MjkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMjAgNTI5LTIwdjM3NGgtNTI5eiIgZmlsbD0iI2YzODMxZiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNTI5IDApIi8+PC9zdmc+");
    background-size: cover;
    position: absolute;
    padding: 2em 1em;
    width: 100%;
    bottom: 0px;
    height: 125px; // how much of the card is revealed by default on all screens        

    @include media-hover() {
      // add transition effect to non touch-screens to reveal additional
      // description and button on hover
      transition: height 0.4s ease-out;

      &:hover {
        height: 300px;
      }
    }
  }
}