@charset "UTF-8";
@import '../_mixins.scss';

.container--ziptrek.zip-pages {
  float:left;
  padding-top: 160px;
  padding-bottom:160px;
  text-transform: uppercase;
  background-color: #fff;
  div.feature-1 {
    a{
      dt {
        /* ../assets/slant-blue.svg */
        background-image: url(data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MjkiIGhlaWdodD0iMzc0IiB2aWV3Qm94PSIwIDAgNTI5IDM3NCI+CiAgPHBvbHlnb24gZmlsbD0iIzMyYWFlZSIgcG9pbnRzPSIwIDIwIDUyOSAwIDUyOSAzNzQgMCAzNzQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDUyOSAwKSIvPgo8L3N2Zz4K);
      }
    }
  }

  div.feature-2 {
    a {
      dt {
        /* ../assets/slant-green.svg */
        background-image: url(data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MjkiIGhlaWdodD0iMzc0IiB2aWV3Qm94PSIwIDAgNTI5IDM3NCI+CiAgPHBvbHlnb24gZmlsbD0iIzZjYWQ0NyIgcG9pbnRzPSIwIDIwIDUyOSAwIDUyOSAzNzQgMCAzNzQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDUyOSAwKSIvPgo8L3N2Zz4K);
      }
    }
  }

  div.col-sm-4 {

    & > div {

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }

    }
  }
  .featured-pages{
    @include media-breakpoint-down(xs) {
      margin-bottom: 40px;
    }
  }

  a {
    display: block;
    font-family: $oswald;
    font-size: 20px;
    position: relative;

    &:hover {
      dd em {
        transform: scale(1.1);
      }
    }

    &:hover {
      dl dt {
        padding-top: 40px;
      }
    }

    dl {
      position: relative;
      width: 100%;
      margin: 0;

      dt {

        span {
          display: block;
          padding: 1em;
          position: relative;
          z-index: 2;
          font-weight:400;
          line-height: 30px;
        }

        /* ../assets/slant-orange.svg */
        background-image: url(data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MjkiIGhlaWdodD0iMzc0IiB2aWV3Qm94PSIwIDAgNTI5IDM3NCI+CiAgPHBvbHlnb24gZmlsbD0iI0YzODMxRiIgcG9pbnRzPSIwIDIwIDUyOSAwIDUyOSAzNzQgMCAzNzQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDUyOSAwKSIvPgo8L3N2Zz4K);
        bottom: -44px;
        color: #fff;
        margin: 0;
        overflow: hidden;
        padding-top: 10px;
        position: absolute;
        transition: padding-top $transition-duration;
        width: 100%;
        z-index: 1;
        @include media-breakpoint-up(sm){
          bottom:-30px;
        }
        @include media-breakpoint-down(xs){
          bottom:-30px;

        }
      }

      dd {
        em {
          display: block;
          background-position: 50% 50%;
          background-repeat:  no-repeat;
          background-size: cover;
          height: 100%;
          overflow: hidden;
          text-indent: -1000em;
          transition: transform $transition-duration;
        }

        height: 100%;
        overflow: hidden;
      }

    }
  }

}
