/**
 * Legacy styles for CMS Editor.
 *
 * These are styles that have been applied to CMS content prior to the styles
 * being aligned.  They are still required to style this old content but
 * should not be used for styling any new content.
 *
 */
@charset "UTF-8";
@import "_mixins.scss";
@import "_buttons.scss";

address {
  display: block;
  margin-bottom: 20px;
}

.green {
  color: $green;
}

.orange {
  color: $orange;
}

.big-orange {
  @extend h1;
  color: $orange;
}

.medium-green {
  @extend h3;
  font-size: 33px;
  font-weight: 400;
  color: $green;
}

.ziptrek-button {
  @extend .btn-zip;
  @extend .btn-green;
}

.ziptrek-button-large {
  @extend .ziptrek-button;
}

.pull-out-quote-text {
  color: $green;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin: 30px 0 30px 20%;
  text-align: right;
  text-transform: uppercase;
  font-family: $oswald;
}