@charset "UTF-8";
@import '../_mixins.scss';

// position arrows on latest-news and testimonial carousels
.carousel-control-next,
.carousel-control-prev {
  width: 20px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

// style the large carousel that appears on the homepage
.zip-carousel {
  padding: 0px;
  background-color: $darkgrey;
  color: #fff;
  position: relative;
  text-align: center;

  .carousel-background {
    position: absolute;
    left: 0px;

  }

  b {
    &.trip-advisor {
      background-image: url(../images/trip-advisor-white.svg);
    }

    &.yelp {
      background-image: url(../images/yelp-white.svg);
    }
  }

  h3 {
    color: $orange;
    font-family: $felt-tip;
    font-size: 60px !important;
    line-height: 51px !important;
    margin: 0 10%;
    position: relative;
    text-transform: none;

    @include media-breakpoint-down(sm) {
      font-size: 45px !important;
      line-height: 31px !important;
    }

    a {
      color: $orange;
      text-decoration: none;
      transition: color $transition-duration;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .carousel-item {
    padding: 100px 0;

    >div {
      padding: 60px 0;
      height: 400px;

      @include media-breakpoint-down(xs) {
        padding: 0px;
      }

      @include media-breakpoint-down(sm) {
        height: 600px;
      }

      blockquote {
        font-style: italic;
        width: 60%;
        margin: 1em auto;
        position: relative;
      }

      dl {
        position: relative;
        margin-bottom: 0;
      }

      dt {
        b {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          height: 50px;
          margin: 0 auto 1em auto;
          width: 50px;
          text-indent: -1000em;
          overflow: hidden;
        }

        font-weight: bold;
      }

    }
  }

  ol.carousel-indicators {


    li {
      &.active {
        background: $orange;
      }

      border-top: 5px;
      border-bottom: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
      margin: 5px;
    }
  }

}