@charset "UTF-8";
@import '_mixins.scss';
@import 'components/_carousel.scss';
@import 'components/_hero.scss';
@import 'components/_instagram.scss';
@import 'components/_pages.scss';
@import 'components/_tours.scss';
@import 'components/_footer.scss';
@import 'components/_navbar.scss';
@import 'components/_our-team.scss';
@import 'components/_text.scss';
@import 'components/_backgrounds.scss';
@import 'components/_buttons.scss';
@import 'components/_editor-legacy.scss';

// This should be a Boostrap shorthand utility, but it doesn't seem to work
// so let's MAKE it work.  U-S-A! U-S-A!.  This utility is needed for
// the zoom "laxer" effect.
.overflow-hidden {
  overflow: hidden;
}

html {
  background: #ededed;
}

.container--ziptrek,
.internal-wrapper {
  max-width: 1280px;
  margin: 0 auto;

  >.row {
    margin-left: 0;
    margin-right: 0;
  }
}

.container--ziptrek {
  position: relative;
}

.VWHomePage #internal-wrapper {
  background: #fff;
}

#internal-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

#small-content-blocks {
  float: left;
  padding: 40px;
  background-color: #FFF;
  margin-top: -5px;
  background: url("../images/green-tree-background.png") repeat scroll center top transparent;
}

.small-content-block {
  float: left;
  margin: 40px 0px;
}

.small-content-block-left {
  float: left;
  width: 60%;
  margin-right: 5%;
}

.small-content-block-right {
  float: right;
  width: 33%
}

.small-content-block-right img {
  width: 100%;
  border: 3px solid #FFF;
}

.small-content-block-left h3 {
  margin-bottom: 30px;
}

@media (max-width: 767px) {

  .small-content-block-right,
  .small-content-block-left {
    width: 100%;
    margin: 20px 0px;
  }

}