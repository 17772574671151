@charset "UTF-8";
@import '../_mixins.scss';

#internal-wrapper>footer {

  // begin slanty top-border
  &:before {
    content: '';
    position: absolute;
    border-top: 50px solid #fff;
    left: -10px;
    right: -10px;
    top: -15px;

    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 50% 50%;

    -webkit-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  background: $orange;
  color: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  width:100%;

  &>* {
    padding: 0 30px;

    @include media-breakpoint-up(md) {
      padding-left: 45px;
      padding-right: 45px;
    }
  }

  // end slanty top
  // begin footer menu
  nav.site {

    dl {
      text-align: left;
      margin-bottom: 0;

      dt {
        font-family: oswald;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
          display: block;
          padding-bottom: 25px;
        }
      }


      dd {
        font-family: $dax;
        /*display: none;*/
        margin-bottom: 8px;

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

    }
  }
}