@charset "UTF-8";
@import '../_mixins.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $oswald;
  text-transform: uppercase;
}

h1 {
  /* Bootstrap default is 2.5rem (40px @ 16), bump to to 3rem (48px) */
  font-size: 3rem;
}

a {
  color: $blue;
  text-decoration: none;
}

a:hover {
  color: $blue;
}

#internal-wrapper {
  font-family: $dax;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }
}

body {
  font-family: $dax;
  font-size: 18px;
}

.text-orange {
  color: $orange;
}

/* legacy style used in some CMS content */
.orange-text {
  color: $orange;
}

.text-green {
  color: $green;
}

.text-blue {
  color: $blue;
}

.text-grey {
  color: $grey;
}

.text-dark-grey {
  color: #535353;
  font-weight: 400;
}

.font-oswald {
  font-family: $oswald;
}

.font-dax {
  font-family: $dax;
}

.font-felt-tip {
  font-family: $felt-tip;
}

// the default bootstrap is being overriden somewhere, not sure where
b,
strong,
th {
  font-weight: bold;
}