.OurTeamPage{
  #team-member-modal-wrapper{
    .modal-dialog{
      max-width: 800px;
    }
    #team-member-widget{
      padding:40px;
    }
    h1{
      display:inherit;
      @include media-breakpoint-down(sm){
          font-size: 36px;
      }
    }
    #team-member-origin{
      display: inline-block;
    }
    h2{
      @include media-breakpoint-down(sm){
        font-size:24px;
      }        
    }
    h1,h2,h3{
      padding-top: 20px;
    }
    #team-member-origin,#team-member-position{
      color:$darkgrey;
    }
  }
  .team-members{
    float:left;
    padding:40px;
    background-color: #fff;
    @include media-breakpoint-down(md){
      padding: 8px;
    }
  }
  .pad-bottom{
    padding-bottom: 24px;
  }
  .gram {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 100%;

    span.background-span {
      background-position: 50% 50%;
      background-size: cover;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform $transition-duration;
      width: 100%;
    }
    span.details{
      padding:20px;
      display:block;
      @include media-breakpoint-down(md){
        padding: 4px;
      }
    }
    span.name {
      font-size: 24px;
      @include media-breakpoint-down(md){
        font-size: 16px;
      }
    }
    span.origin{
      font-size:20px;
      @include media-breakpoint-down(md){
        font-size: 12px;
      }
    }
    span.pop-out{
      position: absolute;
      transform: rotate(90deg);
      bottom:0px;
      right:0px;
      font-size:28px;
      @include media-breakpoint-down(md){
        font-size: 16px;
      }
    }

    @include media-hover {
      &:hover {
        span.background-span {
          transform: scale(1.2);
        }
        div.dark {
          opacity: 1;
          padding-top: 10%;
        }
      }
    }

    > a {
      background-size: cover;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      text-decoration: none;
      top: 0;
      width: 100%;
      z-index: 2;

      div {
        background: rgba(0,0,0,0.6);
        color: #fff;
        cursor: pointer;
        display: block;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        padding: 20px;
  //        text-align: center;
        transition: opacity $transition-duration, padding-top 0.2s;
        width: 100%;
        z-index: 1;

        pre {
          color: #fff;
  //          font-size: 0.8rem;
          font-size: 1em;
          text-align: left;
          white-space: pre-wrap;
          font-family: $dax;
          overflow: hidden;
          background-color: initial;
          padding:0px;
          font-size: 16px;
        }

      }
    }
  }
}
