@import './buttons.scss';

header {

  .zip-shadow {
    background: linear-gradient(#000, transparent);
    height: 160px;
    position: absolute;
    top: 0;
    z-index: -2;
    left: 0;
    width: 100%;
  }
}

body.scrolled {
  header .zip-shadow {
    display: none;
  }
}

nav.navbar {
  z-index: 112;
}

.navbar-nav {
  margin: initial;
}

.navbar-nav>li>a {
  line-height: initial;

  @include media-breakpoint-down(sm) {
    color: #333333;
  }
}

.navbar-nav>li>ul>li>a {
  color: #333333;
  text-align: left;
}

.dropdown-menu {
  left: 20px;
  min-width: 235px;
  margin-top: 0.5rem
}

header .container--ziptrek {
  padding-left: 0;
  padding-right: 0;
}

.navbar {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0px;
  border-top: 15px solid $orange;
  background: white;
  min-height: 90px;
  font-family: $dax;

  @include media-breakpoint-up(md) {
    border: none;
    background: transparent;
  }
}

#fixed-book-now {
  opacity: 0
}

.scrolled #fixed-book-now {
  @include media-breakpoint-down(sm) {
    position: fixed;
    display: block;
    width: 100%;
    z-index: 111;
    top: 0px;
    opacity: 1;
    transition: opacity $transition-duration
  }
}

button.navbar-toggler {
  position: absolute;
  right: 15px;
  top: 15px;
  line-height: 0;
  outline: none;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  opacity: 1;

  &.collapsed .navbar-toggler-icon {
    background: url(../images/hamburger.svg);
  }
}

.navbar-toggler-icon {

  background: url(../images/close-black.svg);
  background-size: contain;
  background-position: 50% 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;

  transition: background $transition-duration;

}

.navbar-logo {
  background: url(../images/logo-stamp.svg) no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  display: block;
  position: absolute;
  height: 90px;
  left: 5px;
  overflow: hidden;
  text-indent: -1000em;
  top: -18px;
  transition: width $transition-duration, height $transition-duration, top $transition-duration, transform $transition-duration, height $transition-duration, width $transition-duration;
  width: 135px;
  z-index: 5;

  @include media-breakpoint-up(md) {
    height: 140px;
    top: 45px;
    width: 159px;

    &:hover {
      transform: scale(1.1) rotate(-1deg);
    }
  }

  @include media-breakpoint-up(lg) {
    height: 140px;
    top: 25px;
    width: 200px;

    &:hover {
      transform: scale(1.1) rotate(-1deg);
    }
  }

  @include media-breakpoint-up(xl) {
    left: 45px;
  }

  .scrolled & {
    top: 5px;
    width: 130px;
    height: 90px;
    z-index: 5;
  }
}


.navbar__mobile-links {

  @include media-breakpoint-up(md) {
    display: none;
  }
}

body.scrolled .navbar__book-now.btn.btn-orange {
  @extend .btn-orange;
}

body.scrolled .navbar__book-now.btn {
  @extend .btn-outline-orange;
  z-index: 3;
}

.navbar__book-now.btn {
  @extend .btn-orange;
  z-index: 3;

  @include media-breakpoint-down(md) {
    position: absolute;
    right: 50px;
    top: 10px;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    right: 20px;
    top: 100px;

    .scrolled & {
      right: 20px;
      top: 16px;
    }
  }
}

.nav-item-md-down-hide {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.orange-bg {

  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    background: url(../images/ziptrek-orange-bg.png);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 76px;
  }

  transition: background $transition-duration;
}

body.scrolled {
  .orange-bg {
    @include media-breakpoint-up(md) {
      background: $orange;
    }
  }
}


#quicklinks {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 76px;
  }
}

#navbarSupportedContent {

  padding: 100px 1em 0 1em;

  @include media-breakpoint-up(md) {
    padding: 0;
    position: relative;
    z-index: 2;
    top: 80px;
    color: white;

    .scrolled & {
      top: -5px;
    }
  }
}

.quicklinks__menu {
  display: none;
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(md) {
    display: flex;
    position: absolute;
    left: 0;
    top: -8px;
    bottom: 0;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .scrolled & {
    display: none;
  }

  li {
    margin: 0 5px 0 0;
    border-radius: 20px;
    display: block;
    list-style: none;
    margin-left: 5px;
    padding: 0;
    font-family: $oswald;
    text-transform: uppercase;
    transition: background .2s;
    height: 30px;
    width: 30px;
    text-align: center;

    &:hover {
      background: $green;
    }

    &:last-child {
      margin-right: 30px;
    }

    a {
      color: $orange;

      &:hover {
        color: #fff;
      }

      text-decoration: none;
      text-transform: uppercase;
    }
  }

  li.quicklinks__lang {
    a {
      color: #fff;
      position: relative;
      top: 2px;
    }

    &.active {
      background: white;

      a {
        color: $orange;
      }
    }
  }

  li.quicklinks__call {
    background: transparent !important;
    font-size: 18px;
    font-weight: bold;
    font-family: $oswald;
    width: auto;
    color: #fff;

    a {
      color: #FFF;
    }

    a:hover {
      text-decoration: underline;
    }

  }
}

#header-nav {

  //if you want the navbar to be fixed for mobile, then move this out of the wrapper,
  //however the menu will likely overflow cutting off for some mobile users
  //this would be the same if using bootstaps native navbar-fixed properties
  @include media-breakpoint-up(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 51;
  }
}

body.scrolled #header-nav {
  background: none;

  @include media-breakpoint-up(md) {
    .navbar-nav {

      .active a {
        color: #fff;
        border-bottom: 3px solid #fff;

        &:hover {
          color: #fff;
        }
      }

      a:hover {
        color: #000;
      }
    }
  }
}


.navbar-nav {

  a {
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 24px;

    &:hover {
      color: $orange;
    }

    border-bottom: 3px solid transparent;
  }

  .active a {
    border-bottom: 3px solid $orange;
  }

  .nav-item.nav-item--call {

    &:hover {
      background: $green;
    }

    background: $orange;
    border-bottom: none;
    border-radius: 40px;
    margin-bottom: 10px;
    text-align: center;

    transition: background $transition-duration;

    a {
      color: #fff;
    }
  }

  @include media-breakpoint-down(sm) {
    li {
      border-bottom: 1px solid #aaa;
      font-size: 24px;
      line-height: 36px;

      &:last-child {
        border-bottom: none;
      }
    }

    .active a {
      color: $orange;
      border-bottom: none;
    }

    ul.dropdown-menu {
      border: none;
      padding-left: 20px;
      padding-top: 0px;
    }

    li.dropdown {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    a:hover {
      text-decoration: none
    }
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    align-items: center;
    justify-content: center;

    a {
      color: #fff;
      display: block;
      height: 100%;
      text-align: center;
      transition: color .2s;
      font-size: 18px;
      padding: 10px 13px !important;
    }
  }

  @include media-breakpoint-up(lg) {

    a {
      padding: 10px 30px !important;
    }
  }



  .nav-item.navbar__mobile-links ul {
    margin: 0;
    padding: 20px 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      border: none;

      a {
        font-weight: normal;
        padding: 2px 0;
        color: #666;
        font-size: 18px;

        &:hover {
          text-decoration: underline;
        }
      }

      div.social {
        ul {

          &:after {
            @include clear-float;
          }

          li {
            list-style: none;
            float: left;
            margin-right: 8px;
            transition: background $transition-duration;
            border-radius: 100px;

            &:last-child {
              margin-right: 0;
            }

            @include media-hover {
              &:hover {
                background: #000;
              }
            }

            background: $blue;

            &.instagram a {
              background-image: url(../images/instagram-white.svg);
            }

            &.facebook a {
              background-image: url(../images/facebook-white.svg);
            }

            &.yelp a {
              background-image: url(../images/yelp-white.svg);
              background-position: 55%;
            }

            &.trip-advisor a {
              background-image: url(../images/trip-advisor-white.svg);
              background-size: 70%;
            }

            &.twitter a {
              background-image: url(../images/twitter-white.svg);
              background-position: 55% 50%;
            }


            a {
              background-size: 60% 60%;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              border-radius: 50px;

              display: block;
              height: 38px;
              overflow: hidden;
              text-indent: -1000em;
              transition: transform $transition-duration;
              width: 38px;

            }
          }


        }
      }

    }
  }
}