/**
 * Background images and colors
 */

.bg-blue {
  background-color: $blue;
  color: white;

  &:active,
  &:hover,
  &:visited {
    color: white;
  }

  a,
  a:active,
  a:hover,
  a:visited {
    color: white;
  }
}

.bg-orange {
  background-color: $orange;
  color: white;

  &:active,
  &:hover,
  &:visited {
    color: white;
  }

  a,
  a:active,
  a:hover,
  a:visited {
    color: white;
  }
}

.bg-grey {
  background-color: $darkgrey;
  color: white;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: white;
    text-decoration: none;

    &.link-grey {
      color: $grey;
    }
  }
}

.bg-grey-logo {
  @extend .bg-grey;
  background-position: 50% 20px;
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-image: url("../images/ziptrek-grey.svg");
}

.bg-green {
  background-color: $green;
  color: white;

  a,
  a:active,
  a:hover,
  a:visited {
    color: $blue;
  }
}

// applied mainly to primary content containers
.bg-trees {
  background: url("../images/green-tree-background.png") no-repeat scroll center top #FFF;
}

// applied to the testimonial container
.bg-quote {
  background: url('../images/quote-background.png') center no-repeat transparent;
  color: white;

  a {
    color: white;
  }

  a:hover {
    color: white;
  }

  hr {
    border-top: 1px solid #ccc;
  }
}

// applied to the latest-news container
.bg-blue-striped {
  background: url('../images/latest-news-background.png') center no-repeat transparent;
  color: white;

  a {
    color: white;
  }

  a:hover {
    color: white;
  }

  hr {
    border-top: 1px solid #ccc;
  }
}

// used for footer:  slanted top, orange background, white text and links
.bg-orange-slice {
  &:before {
    content: '';
    position: absolute;
    border-top: 50px solid #fff;
    left: -10px;
    right: -10px;
    top: -15px;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  overflow: hidden;
  position: relative;
  padding-top: 80px; // start contents below the slant
  color: white;
  background: $orange;

  a,
  a:active,
  a:hover,
  a:visited {
    color: white;
  }
}

// Orange diagonal slashes.  More of an accent than a background.
// The slashes can be overlayed off-center between two columns like this:
//
// <div class="container">
//   <div class="row">
//     <div class="bg-slashes bg-slashes-left"></div>
//     <div class="col-6">...</div>
//     <div class="col-6">...</div>
//   </div>
// </div>
//
// You may need to add a margin to the top of the content in the second column
// so it doesn't collide with the slashes.  If using .bg-slashes-right, then
// add the margin to the top of the first column instead.
//
.bg-slash {
  background: url('../images/orange-slashes.svg') center no-repeat;
  background-size: contain;

  &-right {
    position: absolute;
    height: 35px;
    width: 240px;
    z-index: 1;
    margin-top: -18px;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
      left: 45%;
    }
  }

  &-left {
    position: absolute;
    height: 35px;
    width: 240px;
    z-index: 1;
    margin-top: -18px;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
      right: 45%;
    }
  }
}

/** Horizonal rule used between summarized blog entries */
hr.hr-blue {
  border-top: 0px;
  border-bottom: 2px solid #30aaec;
}