@charset "UTF-8";
@import '../_mixins.scss';

.zip-hero {
  /*
  position: relative;
  > b {
    position: absolute;
    display: block;
    z-index: 1;
    background: red;

    width: 100%;
    height: 200px;
    top: 0;
    left: 0;
  }
*/

  background: #000;
  color: #fff;
  margin-bottom: 100px;
  padding: 150px 50px 150px 50px;
  position: relative;
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;

  @include media-breakpoint-up(md) {
    padding: 250px 100px 150px 100px;

    h1 {
      font-size: 40px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @include media-breakpoint-down(xs) {
    min-height: 500px !important;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 20px;
    position: relative;
    text-transform: none;
    text-align: center;
    z-index: 10;

    @include media-breakpoint-up(sm) {
      font-size: 60px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 80px;
    }
  }

  #hero-button {
    position: relative;
    font-size: 18px;

    @include media-breakpoint-down(sm) {
      top: 150px;
      right: 90px;
    }

    @include media-breakpoint-down(xs) {
      top: 287px;
      right: 90px;
    }
  }

  video {
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    /*z-index: 5;*/
  }

  .advisor {

    border-radius: 90px;
    background: $orange;
    bottom: -30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 10px;
    position: absolute;
    right: 30px;
    transition: transform 0.2s;

    @include media-breakpoint-up(md) {
      bottom: -50px;
      padding: 15px;
    }

    @include media-hover {
      &:hover {
        transform: scale(1.1) rotate(15deg);
      }
    }


    span {
      background: #fff 50% 50%;
      background-repeat: no-repeat;
      background-size: 90px 90px;
      border-radius: 60px;
      display: block;
      height: 120px;
      overflow: hidden;
      text-indent: -1000em;
      width: 120px;

      @include media-breakpoint-up(md) {
        height: 120px;
        width: 120px;
        background-size: 90px 90px;
      }
    }
  }
}